<template>
    <div>
        <div v-if="name=='long'">
            <img src="@/assets/nh/longsm.jpg" alt="">
        </div>
        <div v-else-if="name=='short'">
            <img src="@/assets/nh/shortsm.jpg" alt="">
        </div>
        <div v-else-if="name=='tgf'">
            <img src="@/assets/nh/tgfsm.jpg" alt="">
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      name: ''
    }
  },
  created () {
    this.name = this.$route.query.name
    // eslint-disable-next-line no-unused-expressions
    this.name == 'long' ? document.title = '长期险' : ''
    // eslint-disable-next-line no-unused-expressions
    this.name == 'short' ? document.title = '短期险' : ''
    // eslint-disable-next-line no-unused-expressions
    this.name == 'tgf' ? document.title = '推广费' : ''
  }
}
</script>
<style lang="stylus">

</style>
